import request from '@/utils/request'

const api = {
  user: '/admincp/order/index',
  userinfo: '/admincp/order/info',
  create: '/admincp/order/create',
  update: '/admincp/order/update',
  comfirmUpdate: '/admincp/order/comfirm',
  statuscancel: '/admincp/order/status/cancel',
  updateyuyue: '/admincp/order/status/yuyue',
  updateFinishYuyue: '/admincp/order/status/finish/yuyue',
  updateFinishStatus: '/admincp/order/finish/status',
  updatedemand: '/admincp/order/update/demand',
  OrderDownload: '/admincp/order/download',
  updateNewRemarks: '/admincp/order/updateNewRemarks'
}

export function FetchList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.user,
    method: 'post',
    data: parameter
  })
}

export function create (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.create,
    method: 'post',
    data: parameter
  })
}

export function getById (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userinfo,
    method: 'post',
    data: parameter
  })
}

export function update (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.update,
    method: 'post',
    data: parameter
  })
}
export function comfirmUpdate (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.comfirmUpdate,
    method: 'post',
    data: parameter
  })
}

export function statuscancel (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.statuscancel,
    method: 'post',
    data: parameter
  })
}

export function updateyuyue (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.updateyuyue,
    method: 'post',
    data: parameter
  })
}

export function updateFinishStatus (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.updateFinishStatus,
    method: 'post',
    data: parameter
  })
}

export function updateFinishYuyue (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.updateFinishYuyue,
    method: 'post',
    data: parameter
  })
}

export function updatedemand (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.updatedemand,
    method: 'post',
    data: parameter
  })
}

export function OrderDownload (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.OrderDownload,
    method: 'post',
    responseType: 'blob',
    data: parameter
  })
}

export function updateNewRemarks (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.updateNewRemarks,
    method: 'put',
    data: parameter
  })
}
